<template>
<div class="wrap">
    <div class="mg-auto" >
        <div class="con_top">
            <h2 class="mb-20"><span>■</span> 기업 수정</h2>

            <div class="con">
                <div class="con_table">
                    <table width="900" border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <tbody>
                            <tr>
                                <th>기업명 <span class="red">*</span></th>
                                <td class="left">
                                    <input type="text" v-model="name" class="ml-20 w-200px">
                                </td>
                                <th>기업코드</th>
                                <td class="left">
                                    {{ companyCode }}
                                </td>
                            </tr>
                            <tr>
                                <th>담당자</th>
                                <td class="left">
                                    <input type="text" v-model="manager" class="ml-20 w-200px">
                                </td>
                                <th>연락처</th>
                                <td class="left">
                                    <input type="text" v-model="phone1" class="ml-20 w-50px"><span class="ml-10">-</span>
                                    <input type="text" v-model="phone2" class="ml-10 w-50px"><span class="ml-10">-</span>
                                    <input type="text" v-model="phone3" class="ml-10 w-50px">
                                </td>
                            </tr>
                            <tr>
                                <th>담당 Email</th>
                                <td class="left" colspan="3">
                                    <input type="text" v-model="managerEmail" class="ml-20 w-200px">
                                </td>
                            </tr>
                            <tr>
                                <th>기업메모</th>
                                <td class="left" colspan="3">
                                    <textarea v-model="memo" class="ml-20 h-100px w-98per" value="" />
                                    </td>
                            </tr>
                        </tbody>
                    </table>

                    <div class="btns3 mgB80">
                        <a class="btn_sms mr-10 pointer" @click="cancel()">취소</a>
                        <a class="btn_cancle pointer" @click="modify()">수정</a>
                    </div>

                    <div class="clear"></div>

                    <div class="con_top">
                        <h2><span>■</span> 계약정보 이력</h2>                     

                        <a class="btn_new pointer float-right" @click="addContractPage()">등록</a>
                    </div>

                    <table border="0" cellspacing="0" cellpadding="0" class="col_table mb-00">
                        <colgroup>
                            <col width="5%" />
                            <col width="15%" />
                            <col width="6%" />
                            <col width="6%" />
                            <col width="6%" />
                            <col width="6%" />
                            <col width="6%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="5%" />
                            <col width="10%" />
                            <col width="10%" />
                            <col width="5%" />
                            <col width="5%" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>계약시작일~종료일</th>
                                <th>MSS(계약금액)</th>
                                <th>프로그램(계약금액)</th>
                                <th>상담(총계약금액)</th>
                                <th>계약총액</th>
                                <th>상담 1회비용</th>
                                <th>지원회기</th>
                                <th>상담총회기</th>
                                <th>검사가능여부</th>
                                <th>가족지원여부</th>
                                <th>유형</th>
                                <th>등록일</th>
                                <th>수정</th>
                                <th>삭제</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) of contractList" :key="index">
                                <td>{{ item.rownum }}</td>
                                <td>{{ setDateToString(item.consStartDate) }} ~ {{ setDateToString(item.consEndDate) }}</td>
                                <td>{{ numberWithCommas(item.mssPrice) }}</td>
                                <td>{{ numberWithCommas(item.programPrice) }}</td>
                                <td>{{ numberWithCommas(item.consPrice) }}</td>
                                <td>{{ numberWithCommas(item.totalPrice) }}</td>
                                <td>{{ numberWithCommas(item.interviewConsPrice +  item.videoConsPrice +
                                    item.phoneConsPrice) }}</td>
                                <td>{{ numberWithCommas(item.consCount) }}</td>
                                <td>{{ numberWithCommas(item.consTotalCount) }}</td>
                                <td>{{ item.testYn }}</td>
                                <td>{{ item.familyYn }}</td>
                                <td>{{ item.type }}</td>
                                <td>{{ setDateToString(item.createDate) }}</td>
                                <td class="underline pointer" @click="modContractPage(item.idx)">수정</td>
                                <td class="underline pointer txt-red" @click="removeContract(item.idx)">삭제</td>
                            </tr>
                            <tr v-if="contractList.length === 0">
                                <td colspan="15">정보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- <div class="con_table">
                        <div class="page">
                            <ul>
                                <li>
                                    <a class="pointer" @click="prevPaging()"> &lt; </a>
                                </li>
                                <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                    <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                        <a :class="{'bold': index === pageNum}">{{index}}</a>
                                    </span>
                                </li>
                                <li>
                                    <a class="pointer" @click="nextPaging()"> &gt; </a>
                                </li>

                            </ul>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {
    EmptyCheck
} from '@/utils/validation.js'
import {
    GetUrlParams, DateToString
} from '@/utils/common.js'
export default {
    data: () => ({
        idx: 1, // 기업정보 고유 idx
        name: '', // 기업명
        manager: '', // 담당자
        phone1: '', // 휴대폰 앞자리
        phone2: '', // 휴대폰 가운데자리
        phone3: '', // 휴대폰 마지막자리
        memo: '', // 기업메모
        companyCode:'',//기업코드
        managerEmail: '', // 담당 이메일
        contractList:[{ //히스토리
            idx: 0, 
            crmCompanyIdx: 0, 
            consStartDate: '', // 계약시작일
            consEndDate: '', // 계약종료일
            totalPrice: '', // 계약총액
            price: '', // 상담1회비용
            consCount: '', // 지원회기
            testYn: '', // 검사가능여부 Y/N
            familyYn: '', // 가족지원여부 Y/N
            type: '', // 유형 (찾아가는상담/전화/화상/법률/채팅/대면상담)
            mssPrice: 0, // MSS 계약금액
            programPrice: 0, // 프로그램 계약금액
            consPrice: 0, // 상담 총계약금액
            consTotalCount: 0, // 상담총회기
            createDate : '',
        }],
    }),

    mounted() {
        var oParams = GetUrlParams()
        this.idx = oParams.idx

        this.getCompanyData(this.idx)
    },

    methods: {
        // 기업 정보 데이터 api 호출
        getCompanyData(idx) {
            var params = {
                idx: idx
            }

            this.axios.get(`/api/v1/company/${idx}`, {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.name = res.data.company.name
                        this.manager = res.data.company.manager
                        this.memo = res.data.company.memo
                        this.companyCode = res.data.company.company_code;
                        this.managerEmail = res.data.company.manager_email

                        // 휴대폰 번호 분할하기
                        if (res.data.company.phone.split('-').length === 3) {
                            var phone = res.data.company.phone.split('-')
                            this.phone1 = phone[0]
                            this.phone2 = phone[1]
                            this.phone3 = phone[2]
                        }

                        this.contractList = res.data.companyContract    
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 수정
        modify() {
            // 기업명 검사
            if (!EmptyCheck(this.name, '기업명을')) {
                return false
            }

            // 핸드폰 번호를 전부 입력한 경우에만 핸드폰 포맷으로 파싱
            if (this.phone2 !== '' && this.phone2 !== '' && this.phone3 !== '') {
                var phone = this.phone1 + '-' + this.phone2 + '-' + this.phone3
            } else {
                var phone = ''
            }

            var vo = {
                companyCode: this.companyCode,
                name: this.name,
                manager: this.manager,
                phone: phone,
                memo: this.memo,
                managerEmail: this.managerEmail,
            }

            this.axios.put(`/api/v1/company/${this.idx}`, vo)
                .then(res => {
                    if (res.data.err === 0) {
                        alert('기업수정이 완료되었습니다.')
                        this.$router.push('/origin/company_manage')
                    } else {
                        alert(res.data.result)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },

        // 이전 페이지로 이동
        cancel() {
            this.$router.push('/origin/company_manage')
        },
        //계약 등록페이지
        addContractPage() {
            this.$router.push({ name: 'origin-companyContractRegister', query: { crmCompanyIdx: this.idx } })
        },
        //계약 수정페이지
        modContractPage(idx) {
            this.$router.push({ name: 'origin-companyContractModify', query: { idx: idx } })
        },
        removeContract(idx) {
            var con_test = confirm("삭제하면 복구가 불가능합니다.\n정말 삭제하시겠습니까?");
            if (con_test == true) {
                this.axios.delete(`/api/v1/companyContract/${idx}`, 
                    )
                    .then(res => {
                        if (res.data.err === 0) {
                            alert('삭제되었습니다.')
                            this.getCompanyData(this.idx)
                        } else {
                            alert(res.data.result)
                        }
                    })
                    .catch(err => {
                        console.log(err)
                        alert(err)
                    })
            } else {
                return false
            }
        },
        //yyyy-mm-dd format
        setDateToString(date) {
            return DateToString(date)
        },
        // 컴마 넣기
        numberWithCommas(x) {
            if(x === undefined) {
                return 0
            } else {
                x = x + ''
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        },
    }
}
</script>
